exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-500-index-jsx": () => import("./../../../src/pages/500/index.jsx" /* webpackChunkName: "component---src-pages-500-index-jsx" */),
  "component---src-pages-about-bipolar-depression-index-jsx": () => import("./../../../src/pages/about-bipolar-depression/index.jsx" /* webpackChunkName: "component---src-pages-about-bipolar-depression-index-jsx" */),
  "component---src-pages-about-schizophrenia-index-jsx": () => import("./../../../src/pages/about-schizophrenia/index.jsx" /* webpackChunkName: "component---src-pages-about-schizophrenia-index-jsx" */),
  "component---src-pages-bipolar-adverse-reactions-index-jsx": () => import("./../../../src/pages/bipolar/adverse-reactions/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-adverse-reactions-index-jsx" */),
  "component---src-pages-bipolar-disease-severity-index-jsx": () => import("./../../../src/pages/bipolar/disease-severity/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-disease-severity-index-jsx" */),
  "component---src-pages-bipolar-eps-akathisia-index-jsx": () => import("./../../../src/pages/bipolar/eps-akathisia/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-eps-akathisia-index-jsx" */),
  "component---src-pages-bipolar-pooled-data-index-jsx": () => import("./../../../src/pages/bipolar/pooled-data/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-pooled-data-index-jsx" */),
  "component---src-pages-bipolar-primary-efficacy-index-jsx": () => import("./../../../src/pages/bipolar/primary-efficacy/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-primary-efficacy-index-jsx" */),
  "component---src-pages-bipolar-quality-of-life-index-jsx": () => import("./../../../src/pages/bipolar/quality-of-life/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-quality-of-life-index-jsx" */),
  "component---src-pages-bipolar-weight-metabolics-index-jsx": () => import("./../../../src/pages/bipolar/weight-metabolics/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-weight-metabolics-index-jsx" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-disclosures-index-jsx": () => import("./../../../src/pages/disclosures/index.jsx" /* webpackChunkName: "component---src-pages-disclosures-index-jsx" */),
  "component---src-pages-dosing-index-jsx": () => import("./../../../src/pages/dosing/index.jsx" /* webpackChunkName: "component---src-pages-dosing-index-jsx" */),
  "component---src-pages-efficacy-summary-index-jsx": () => import("./../../../src/pages/efficacy-summary/index.jsx" /* webpackChunkName: "component---src-pages-efficacy-summary-index-jsx" */),
  "component---src-pages-formulary-access-index-jsx": () => import("./../../../src/pages/formulary-access/index.jsx" /* webpackChunkName: "component---src-pages-formulary-access-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mechanism-of-action-index-jsx": () => import("./../../../src/pages/mechanism-of-action/index.jsx" /* webpackChunkName: "component---src-pages-mechanism-of-action-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-safety-summary-index-jsx": () => import("./../../../src/pages/safety-summary/index.jsx" /* webpackChunkName: "component---src-pages-safety-summary-index-jsx" */),
  "component---src-pages-samples-support-index-jsx": () => import("./../../../src/pages/samples-support/index.jsx" /* webpackChunkName: "component---src-pages-samples-support-index-jsx" */),
  "component---src-pages-savings-card-index-jsx": () => import("./../../../src/pages/savings-card/index.jsx" /* webpackChunkName: "component---src-pages-savings-card-index-jsx" */),
  "component---src-pages-schizophrenia-adverse-reactions-index-jsx": () => import("./../../../src/pages/schizophrenia/adverse-reactions/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-adverse-reactions-index-jsx" */),
  "component---src-pages-schizophrenia-eps-akathisia-index-jsx": () => import("./../../../src/pages/schizophrenia/eps-akathisia/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-eps-akathisia-index-jsx" */),
  "component---src-pages-schizophrenia-panss-subscales-index-jsx": () => import("./../../../src/pages/schizophrenia/panss-subscales/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-panss-subscales-index-jsx" */),
  "component---src-pages-schizophrenia-primary-efficacy-index-jsx": () => import("./../../../src/pages/schizophrenia/primary-efficacy/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-primary-efficacy-index-jsx" */),
  "component---src-pages-schizophrenia-prosocial-subscale-index-jsx": () => import("./../../../src/pages/schizophrenia/prosocial-subscale/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-prosocial-subscale-index-jsx" */),
  "component---src-pages-schizophrenia-weight-metabolics-index-jsx": () => import("./../../../src/pages/schizophrenia/weight-metabolics/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-weight-metabolics-index-jsx" */),
  "component---src-pages-sitemap-index-jsx": () => import("./../../../src/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-sitemap-index-jsx" */)
}

