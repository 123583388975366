import { debounce, extractTextFromElement } from '@utils/helper.js';

let distanceScrolled = -1;
let distanceThreshold = [
    { threshold: 0, value: 'Baseline', sent: false },
    { threshold: 25, value: '25%', sent: false },
    { threshold: 50, value: '50%', sent: false },
    { threshold: 75, value: '75%', sent: false },
    { threshold: 100, value: '100%', sent: false },
];

const scrollDepth = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    let progress = Math.round(
        (scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100
    );

    if (isNaN(progress)) progress = 0;

    if (progress > distanceScrolled) {
        distanceScrolled = progress;

        for (let i = 0; i < distanceThreshold.length; i++) {
            const status = distanceThreshold[i].sent;
            const threshold = distanceThreshold[i].threshold;
            const value = distanceThreshold[i].value;

            if (!status && progress >= threshold) {
                if ('dataLayer' in window) {
                    window.dataLayer.push({
                        event: 'gatsbyScroll',
                        gatsbyScrollDistance: value,
                    });
                    distanceThreshold[i].sent = true;
                }
            }
        }
    }
};

const scrollListener = debounce('scrollDepth', scrollDepth, 18);

const GTM = {
    init: () => {
        const interval = setInterval(() => {
            if ('dataLayer' in window && 'google_tag_manager' in window) {
                window.dataLayer.push({ event: 'gatsby-route-change' });
                clearInterval(interval);
            }
        }, 100);
        scrollDepth();
        document.addEventListener('scroll', scrollListener);
    },
    // Expand/Collapse START
    toggleMobileMenu: (isExpanded) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'toggleMobileMenu',
                mobileMenuState: isExpanded ? 'Expand' : 'Collapse',
            });
        }
    },
    toggleAccordion: (isExpanded) => {
        window.dataLayer.push({
            event: 'toggleAccordion',
            accordionState: isExpanded ? 'Collapse' : 'Expand',
        });
    },
    // Expand/Collapse END

    // form tags START
    formSubmission: () => {
        if ('dataLayer' in window) {
            window.dataLayer.push({ event: 'formCompletion' });
        }
    },
    formInputChange: (field, value) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'inputChange',
                fieldName: field,
                fieldValue: value,
            });
        }
    },
    formInputError: (field, value) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'formInputError',
                fieldName: field,
                fieldError: value,
            });
        }
    },
    formFieldCompletionFormSubmitted: (field, value) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'formFieldCompletionFormSubmitted',
                fieldName: field,
                fieldValue: value,
            });
        }
    },
    // form tags END
    reset: () => {
        distanceScrolled = -1;
        distanceThreshold.forEach((threshold) => (threshold.sent = false));
    },
};

export default GTM;
